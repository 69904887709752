/**
 * Created by BETALOS on 12/10/2016. [edited by amine 16/10/2019]
 */
(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const {HTMLS} = require('shared/utils/requires');

    class FormDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;

            this.url = this.url || null;
            this.model = this.model || null;
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.http.post(this.url, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }

    const FORM_DIALOG = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: FormDialogCtrl,
        focusOnOpen: false
    };

    class SelectCtrl {
        constructor($http, $attrs, $timeout, $element, $mdDialog) {
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.$http = $http;
            this.$attrs = $attrs;
            this.$element = $element;

            this.items = [];
            this.initItems = [];
            this.dialogTpl = null;
            this.internalValue = null;
            this.eventSubject = new Subject();
            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["$http", "$attrs", "$timeout", "$element", "$mdDialog"];
        }

        $onInit() {
            this.assign = this.assign || {};
            this.filter = this.filter || {};

            this.$http.get(this.$attrs['url']).then(response => {
                this.items = this.initItems = _.chain(response.data).filter(this.filter).sortBy(this.$attrs['value']).value();

                if (this.$element.is('[auto-select]') && this.items.length > 0) {
                    this.internalValue = this.items[0];
                    this.internalValueChanged();
                }
            });

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            const item = _.find(this.initItems, ['id', _.get(this.internalValue, 'id', null)]);
            this.items = _.chain(items).pushOrUpdate(item).compact().uniqBy('id').sortBy(this.$attrs['value']).value();
        }

        mdSelectEvent(event) {
            this.eventSubject.next(event);
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }

        add(ev) {
            const dialogConfig = _.assign({}, FORM_DIALOG, {
                targetEvent: ev,
                template: HTMLS[this.dialogTpl],
                locals: {
                    url: this.$attrs['url'],
                    model: _.cloneDeep(this.assign)
                },
            });

            this.dialog.show(dialogConfig).then(data => {
                this.initItems = _.chain(this.initItems).concat(data).sortBy(this.$attrs['value']).compact().value();

                this.internalValue = data;
                this.internalValueChanged();
            });
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        elem.addClass('layout-row');

        if (!elem.is('[flex]') && !elem.is('.flex')) elem.addClass('flex-nogrow');

        let value = attrs['value'];
        let label = attrs['label'];

        const multiple = elem.is('[multiple]') ? "multiple" : "";
        const isRequired = elem.is("[required]") ? "required" : "";
        const isDisabled = elem.is('[disabled]') ? "disabled" : "";
        const ngRequired = elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = elem.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";

        if (elem.is("[mn-disabled]") || elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        let search = elem.is('[no-search]') ? "" : `
            <mn-select-search-header subject="vm.eventSubject" search-by="${value}" items="vm.setItems($event)" init-items="vm.initItems"></mn-select-search-header>
        `;

        return `
            <md-input-container flex>
               <label translate-once="${label}"></label>
               <md-select aria-label="select" md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')"
                    ng-model="vm.internalValue" ng-change="vm.internalValueChanged()" ng-model-options="{ trackBy: '$value.id' }"
                    ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled} ${multiple}>

                    ${search}
                    <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                        <span ng-bind="::item.${value}" ></span>
                    </md-option>
               </md-select>
               <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${isDisabled} ng-if="vm.dialogTpl && !vm.disabled" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
               </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            assign: "<?",
            filter: "<?",
            dialogTpl: '@',
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: SelectCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
