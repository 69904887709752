/**
 * Created by amine on 27/08/2018.
 */
(function () {
    'use strict';

    class EmailSharingDialogCtrl {
        constructor($mdDialog, patientService, $mdToast, $translate) {
            this.uid = null;
            this.dialog = $mdDialog;
            this.patientService = patientService;

            const simpleToast = $mdToast.simple()
                .textContent($translate['instant']('email_patient_email_empty'))
                .position("bottom left")
                .hideDelay(1500);

            this.showToast = () => {
                $mdToast.show(simpleToast);
            }
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "$mdToast", "$translate"];
        }

        $onInit() {
            this.title = this.title || "";
            this.patient = this.patient || null;
            this.readOnly = this.readOnly || false;
            this.message = {
                to: "", content: "", subject: this.title
            };

            this.editorOpts = {
                "context": "email",
                "auto_compile": true,
                "no_config_key": true,
                "patient": this.patient,
                "has_misc_menu": !_.isNil(this.patient),
                "has_patient_menu": !_.isNil(this.patient),
                "has_physician_menu": !_.isNil(this.patient),
                "has_measurement_menu": !_.isNil(this.patient),
            }

            if (_.isNil(this.patient)) {
                this.patientObj = null;
            } else {
                this.patientService.getMinimalPatient(this.patient)
                    .then(data => this.patientObj = data)
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        addPatientEmail() {
            const email = _.get(this.patientObj, "contact_info.email");
            if (!_.isNil(email) && !_.isEmpty(email)) {
                const newTo = _.isEmpty(this.message.to) ? "" : this.message.to.trim().trim(";").replace(/;$/, "") + ";";

                this.message.to = `${newTo} ${email}`;
            } else {
                this.showToast();
            }
        }

        submit() {
            this.dialog.hide({message: this.message, uid: this.uid});
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: EmailSharingDialogCtrl,
        template: require("shared/views/email-sharing-dialog.tpl.html")
    }

})()
