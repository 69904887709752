/**
 * Created by BETALOS on 27/01/2017.
 */
(function () {

    'use strict';

    const NOTIFICATION_LIST_DIALOG = require('../dialogs/notification-list-dialog');

    class NotificationCtrl {
        constructor(notificationService, $mdMenu, $mdDialog, $mdToast) {
            this.menu = $mdMenu;
            this.toast = $mdToast;
            this.dialog = $mdDialog;
            this.service = notificationService;

            this.notifications = null;
            this.audio = new Audio('/img/alarm.mp3');
        }

        static get $inject() {
            return ["notificationService", "$mdMenu", "$mdDialog", "$mdToast"];
        }

        $onInit() {
            this.service.getActiveNotifications()
                .subscribe(data => {
                    let differences = _.differenceBy(data, this.notifications, 'id');
                    if (!_.isNull(this.notifications)) this.handleDifferences(differences);

                    this.notifications = data;
                });
        }

        addNotification(ev) {
            this.service.notificationDialog(null, null, ev)
        }

        showNotifications(ev) {
            this.dialog.show(_.assign({}, NOTIFICATION_LIST_DIALOG, {
                targetEvent: ev,
                locals: {}
            }));
        }

        markAsSeen(item) {
            return this.service.markNotificationsAsSeen([item]);
        }

        markAllAsSeen() {
            return this.service.markNotificationsAsSeen(this.notifications);
        }

        handleDifferences(items) {
            if (items.length > 0) this.triggerAlarm();

            _.forEach(items, (item) => {
                let toast = this.toast.simple()
                    .textContent(item.description)
                    .position("top right")
                    .hideDelay(3000);

                this.toast.show(toast);
            });
        }

        triggerAlarm() {
            return this.audio.play();
        }
    }

    module.exports = {
        bindings: {},
        controllerAs: "vm",
        controller: NotificationCtrl,
        template: require('shared/views/notification.tpl.html'), // or template
    };

})();