/**
 * Created by BETALOS on 11/02/2016.
 */
(function () {
    'use strict';

    const {Subject} = require("rxjs");
    const {debounceTime} = require('rxjs/operators');
    const handleCustomStyle = require('../utils/handle-custom-style');

    module.exports = {
        controller: TableController,
        controllerAs: "vm",
        bindings: {
            mnModel: '@',
            order: '@',
            actions: '=?',
            /*submit: '<',*/
            mnQuery: '=?',
            click: '<trClick',
            reset: '=?',
            selected: "=?",
            promise: '=?', /** added by amine 06/04/2018 - get the table promise outside of the component **/
            alerts: '=?',
            toggle: '=?',
            exportHelpers: '=?'

        },
        template: tpl
    };

    TableController.$inject = ["tableService", "$scope", "$attrs", "$element", "$timeout", "$translate", "$sce", "configService", "system"];

    function TableController(tableService, $scope, $attrs, $element, $timeout, $translate, $sce, configService, system) {
        let vm = this;

        let querySubject = new Subject()
            .pipe(debounceTime(50));

        vm.$onInit = init;

        vm.onReorder = onReorder;
        vm.onPaginate = onPaginate;
        vm.hideColumn = hideColumn;

        vm.getColumn = getColumn;
        vm.getData = _.mnDelay(getData, 400);

        vm.shownColumns = shownColumns;
        vm.handleClasses = handleClasses;

        vm.checkItem = checkItem;
        vm.exportExcel = exportExcel;

        vm.computedStyle = computedStyle;
        vm.handleCallback = handleCallback;
        vm.getColumnName = getColumnName;

        vm.singleAction = singleAction;

        vm.trustAsHtml = $sce.trustAsHtml;
        vm.getIconColor = getIconColor;
        vm.handleToggle = handleToggle;
        vm.toggle = false;

        // custom fields related
        vm.handleCustom = handleCustom;
        vm.handleSingleActionClasses = handleSingleActionClasses;
        vm.handleStyle = handleStyle;

        function handleStyle(type, e, c = null) {
            return handleCustomStyle(type, e, c, vm.styleRules, vm.draftRule, system['date_format'].js);
        }


        // methods
        function onReorder(order) {
            vm.query = _.assign(vm.query, {order: order});
            getData();
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            getSubConfig();
            vm.promise = tableService.getData(vm.mnModel, vm.query, vm.columns).then(done);

            function done(data) {
                vm.total = data.length;
                vm.rowCollection = data.list;

                if (vm.total < ((vm.query.page - 1) * vm.query.limit)) {
                    vm.query.page = 1;
                }
            }
        }

        function getSubConfig() {
            if (_.has($attrs, 'alerts')) {
                configService.get('store_config')
                    .then(data => {
                        vm.storeConfig = data;
                        vm.module = vm.mnModel.split('.').pop();
                    });
            }
        }

        function getIconColor(keyValue) {
            return _.chain(vm.storeConfig['alert_management']).find(
                {model: vm.module}
            ).get('alerts').find({state: keyValue}).get('color').value();
        }

        function hideColumn(column) {
            if (column) column['is_shown'] = !column['is_shown'];

            tableService.updateColumns(vm.mnModel, vm.columns)
                .then(updateColumns);
        }

        function orderChanged() {
            _.forEach(vm.columns, (v, k) => v.order = k);

            hideColumn();
        }

        function updateColumns(data) {
            vm.columns = data.columns;
        }

        function shownColumns() {
            return _.chain(vm.columns).filter('is_shown').size().add(
                vm.actions['single'].length > 0 ? 1 : 0
            ).value();
        }

        function handleClasses(index) {
            return _.reduce(vm.classes, (sum, value) => {
                let test = _.get(vm.rowCollection[index], value.key);
                test = value['opposite'] ? !test : test;
                return test ? _.concat(sum, value['css_class']) : sum;
            }, []);
        }

        function handleSingleActionClasses(action, row, event) {
            return _.get(action, 'class') ? (_.isFunction(_.get(action, 'class')) ? _.get(action, 'class')(row, event) : _.get(action, 'class')) : {};
        }

        function checkItem(item, condition, def) {
            if (_.isNil(condition)) return _.isNil(def) ? true : def;
            return eval(condition);
        }

        function exportExcel(ev) {
            let translatedColumns = _.reduce(vm.columns, reduceFunc, {
                file_name: $translate['instant'](vm.mnModel),
                page_name: $translate['instant']('table_export_excel_page_name')
            });

            let query = _.assign({
                translated_columns: translatedColumns, export_helpers: vm.exportHelpers
            }, _.pick(vm.query, ['search', 'search_all', 'filter']));

            vm.tablePromise = tableService.generateExcel(vm.mnModel, query, ev)
                .then(success);

            function reduceFunc(items, item) {
                return _.set(items, item.label, $translate['instant'](item.label));
            }

            function success(data) {
                data.download();
            }
        }

        function computedStyle() {
            //Todo find a better impl for this functionality
            /*console.log($('md-table-container', $element).width() / $('.md-table-progress th', $element).attr('colspan'));

             return {'max-width': '120px'};*/
        }

        // custom fields related
        function handleCustom(row, column) {
            return _.get(row, column.order_by);
        }

        function init() {
            vm.total = 0;
            vm.columns = [];
            vm.selected = [];
            vm.rowCollection = [];
            vm.handleReload = false;
            vm.styleRules = [];

            /** added by amine 06/04/2018 - to configure the list default size **/
            vm.defaultLimit = parseInt(_.get($attrs, 'defaultLimit', 15));
            vm.query = {
                page: 1,
                limit: vm.defaultLimit,
                order: _.isUndefined(vm.order) ? 'id' : vm.order
            };

            vm.focus = true;

            vm.click = _.isFunction(vm.click) ? vm.click : _.noop;

            vm.actions = _.isObject(vm.actions) ? vm.actions : {single: [], multiple: []};
            vm.query.search = _.isObject(vm.mnQuery) ? vm.mnQuery : {};

            vm.reset = _.has($attrs, 'reset') ? reset : null;
            vm.reload = _.has($attrs, 'reload') ? getData : null;

            vm.getColumn();

            $scope.$on('md-menu-one.drop-model', orderChanged);

            if ($element.is('[mn-query]')) {
                $scope.$watch("vm.mnQuery", queryWatch);
                querySubject.subscribe(
                    () => {
                        getData();
                        vm.promise.then(() => vm.focus = !vm.focus);
                    }
                );
            }

            function queryWatch(value) {
                if (!_.isEqual(value, vm.query.search)) {
                    vm.query.search = value;
                }

                if (vm.columns) reset();
            }

            function reset(query, emptySelection = false, preserve = false) {
                if (emptySelection) vm.selected = [];
                if (_.isNil(query)) query = {};

                vm.query = _.assign(vm.query, query, preserve ? {} : {
                    page: 1,
                    search_all: "",
                    limit: vm.defaultLimit,
                });

                querySubject.next(vm.query);
            }
        }

        function getColumn(reload) {
            vm.promise = tableService.getColumns(vm.mnModel, reload).then(success);

            function success(data) {
                vm.columns = data.columns;
                vm.filters = data.filters;
                vm.classes = data.classes;
                vm.styleRules = _.get(data, 'style_rules', []);

                if (!_.isEmpty(data.default_sort)) {
                    vm.order = data.default_sort;
                    vm.query.order = data.default_sort;
                }

                if (!reload) querySubject.next(vm.query);
            }
        }

        function getColumnName(column) {
            switch (column['order_by']) {
                case 'getArticleStoreQte':
                    let l = _.split(column.label, '$$');
                    return `${$translate.instant(l[1])} ${l[2]}`;
                    break;
                default:
                    return "";
                    break;
            }
        }

        function handleCallback(row, column) {
            switch (column['order_by']) {
                case 'getArticleStoreQte':
                    let res = _.find(row['theo_stores'], (item) => {
                        if (item.warehouse == _.toInteger(_.split(column.label, '$$')[0])) return item;
                    });
                    return !_.isNil(res) ? res[_.split(column.label, '$$')[1]] : 0;
                    break;
                default:
                    return 0;
                    break;
            }
        }

        function singleAction(action, row, event) {
            if (event) event.stopPropagation();
            action.method(row, event);
        }

        function handleToggle(event) {
            vm.toggle = !vm.toggle;
        }
    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl(elem, attrs, $translate) {
        let template = $(require('shared/views/table.tpl.html'));
        let paginationLabel = {
            of: $translate['instant']('of'),
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
        };

        $('md-table-pagination', template)
            .attr('md-limit-options', '[5, 10, 15, 20]')
            .attr('md-label', JSON.stringify(paginationLabel));

        if (elem.is('[selectable]') && _.get(attrs, 'selectable') == 'true') {
            $('table', template)
                .attr({'md-row-select': 'true', 'ng-model': 'vm.selected'})

            $('tbody > tr', template)
                .not(".search-tr")
                .not(".no-element")
                .attr({'md-select': "row", 'md-select-id': "id", 'md-auto-select': 'true'});
        }

        if (elem.is('[tr-click]')) {
            $('tbody > tr', template)
                .not(".search-tr")
                .not(".no-element")
                .attr('ng-click', "vm.click(row, $event)");
        }


        return template.html();
    }

})();
