/**
 * Created by amine on 10/03/2017. edited 25/02/2020
 */
(function () {
    'use strict';

    const getObjectId = require('shared/utils/get-object-id');
    const BASE_FIELD = ["id", "comment"];
    const HEADER_FIELD = BASE_FIELD.concat([
        "payer",
        "payee",
        "payment_mode",
        "total_amount",
        "physician",
        "transaction_uid"
    ]);

    class MainPaymentFormCtrl {
        constructor(paymentService, $translate, system, $state, $stateParams, $scope, configService, $q, moment) {
            this.dateFormat = system['date_format'].js;

            this.transactionUID = getObjectId();

            this.paymentService = paymentService;
            this.configService = configService;
            this.translate = $translate;
            this.state = $state;
            this.q = $q;
            this.moment = moment;
            this.scope = $scope;

            this.encasementId = $stateParams["encasementId"];
            this.patientId = $stateParams["patientId"];

            this.skipChange = {
                payer: false,
                payee: false
            };
            this.field = {
                label: $translate.instant('comment'),
            };
            this.isDental = configService.isDental();
            this.hasMedicalCare = configService.hasMedicalCare();
            this.reset = _.isNil($stateParams["reset"]) ? 0 : $stateParams["reset"];
            this.readOnly = !_.isNil(this.encasementId) && this.reset < 999;
            this.closed = false;
            this.payeeReadOnly = !_.isNil(this.patientId);
            this.invalidatedHeader = false;
            this.amountChanged = false;
            this.amount_label = "payment_received_amount";
            this.model = {
                payment_date: moment(),
                amount: 0,
                physician: {},
                payee: {},
                payee_type: "P",
                details: [],
                payer: {},
                payer_type: "P",
                comment: "",
                transaction_uid: this.transactionUID,
                payment_mode: {
                    due_date: moment()
                }
            };

            this.upload = () => Promise.resolve(false);

            this.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };
            this.selectedPayable = null;
            this.payables = [];
            this.total = 0;
            this.query = {
                page: 1,
                limit: 5,
                order: '-date'
            };
            this.options = [5, 10];
            this.onReorder = order => this._onReorder(order);
            this.onPaginate = (page, limit) => this._onPaginate(page, limit);
            this.selectDetail = this._detailSelection(true);
            this.deselectDetail = this._detailSelection(false);
            this.selectAll = this._allDetailSelection(true);
            this.deselectAll = this._allDetailSelection(false);
        }

        static get $inject() {
            return ["paymentService", "$translate", "system", "$state", "$stateParams", "$scope", "configService", "$q", "moment"];
        }

        $onInit() {
            if (!_.isNil(this.encasementId)) {
                if (this.reset > 0) {
                    this.invalidatedHeader = this.reset > 1;
                    this.paymentService
                        .resetEncasement(this.encasementId)
                        .then((data) => this.loadEncasement(data), () => this.paymentService.mainState());
                } else {
                    this.paymentService
                        .getEncasement(this.encasementId)
                        .then((data) => this.loadEncasement(data), () => this.paymentService.mainState());
                }
            } else if (!_.isNil(this.patientId)) {
                this.model.payee = this.patientId;
            }
        }

        getPayables(all = false) {
            const order = this.query.order[0] === '-' ? 'desc' : 'asc';
            const min = all ? 0 : (this.query.page - 1) * this.query.limit;
            const max = all ? this.payables.length : (this.query.page) * this.query.limit;

            return _.chain(this.payables)
                .orderBy(payable => this.moment(payable.model.date, this.dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        payerChange(force = false) {
            if (this.readOnly && !force) return false;

            this.model.payer = null;
            switch (this.model.payer_type) {
                case "P":
                case "T":
                    this.model.payer = _.cloneDeep(this.model.payee);
                    break;
                case "O":
                    this.model.payer = _.cloneDeep(this.paymentService.getCls("unregistered"));
                    break;
            }
        }

        payeeChange(force = false) {
            if (this.readOnly && !force) return false;

            switch (this.model.payee_type) {
                case "P":
                    this.model.payer_type = "P";
                    break;
                case "T":
                    this.model.payee = _.assign(this.model.payee, this.paymentService.getCls("organization"));
                    this.model.payer_type = "T";
                    break;
            }
        }

        makePayment(encasement) {
            return {
                total_amount: this.getTotal(),
                payment_date: this.model.payment_date,
                encasement: encasement,
                transaction_uid: this.transactionUID,
                details: _.chain(this.model.details).cloneDeep().reduce((result, payable) => {
                    payable.financial_status.paid_amount += payable.model.total_amount;
                    if (payable.financial_status.paid_amount > payable.financial_status.total)
                        payable.financial_status.paid_amount = 0;
                    payable.financial_status.is_validated = true;
                    payable.financial_status.validate_at = this.moment();

                    if (this.model.payee_type === "T")
                        payable.financial_status.organization_affectation.paid_amount += payable.model.total_amount;

                    let detail = {
                        total_amount: payable.model.total_amount,
                        _cls: this.paymentService.getCls(payable.model.type)
                    };

                    switch (payable.model.type) {
                        case "visit":
                        case "medical_plan":
                            detail[payable.model.type] = payable;
                            break;
                        case "plan":
                            detail.treatment_plan = payable;
                            _.unset(detail.treatment_plan, 'global_discount');
                    }

                    return _.concat(result, detail);
                }, []).value()
            }
        }

        submit(exit = false) {
            const deferred = this.q.defer();
            let encasement = {};

            const newUID = _.mnDelay(() => {
                this.transactionUID = getObjectId();
            }, 500);

            const afterValidation = encasement => {
                if (exit) {
                    deferred.resolve(encasement);
                    this.goBack();
                } else {
                    this.paymentService
                        .encasementState(encasement)
                        .then(data => {
                            newUID();
                            this.state.reload();
                            deferred.resolve(data);
                        }, deferred.reject);
                }
            };
            const success = data => {
                this.upload({encasement: _.isUndefined(data.encasement) ? data.id : data.encasement.id})
                    .then(() => {
                        if (_.isUndefined(data.encasement)) afterValidation(data);
                        else {
                            this.paymentService.validateDeliveryDocuments(data.id)
                                .then(() => afterValidation(data.encasement), deferred.reject);
                        }
                    });
            };

            if (_.isEmpty(this.model.payer)) this.payerChange();

            if (this.reset > 1 || !this.model.id) {
                encasement = _.chain(encasement)
                    .assign(this.model)
                    .pick(HEADER_FIELD)
                    .assign({
                        encasement_date: this.model.payment_date,
                        total_amount: this.model.amount
                    })
                    .value();
            } else {
                encasement = _.chain(encasement)
                    .assign(this.model)
                    .pick(BASE_FIELD)
                    .value();
            }

            if (_.isEmpty(this.model.details)) {
                this.paymentService.saveEncasement(encasement)
                    .then(data => success(data), deferred.reject);
            } else {
                let payment = this.makePayment(encasement);

                if (payment.details) {
                    this.paymentService.addPayment(payment)
                        .then(data => success(data), deferred.reject);
                } else {
                    deferred.reject();
                }
            }

            return deferred.promise;
        }

        loadEncasement(data) {
            let amountToShow;
            this.closed = data.remaining_amount === 0;
            if (this.closed) {
                amountToShow = data.total_amount;
            } else {
                this.amount_label = this.reset > 1 ? "payment_received_amount" : "payment_outstanding_amount";
                amountToShow = data.remaining_amount;
            }
            this.title = this.translate.instant('open_encasement', {
                date: data.encasement_date,
                amount: data.total_amount
            });

            this.model = _.assign({}, this.model, {
                id: data.id,
                payment_date: this.reset > 1 || this.closed ? data.encasement_date : this.moment(),
                encasement_date: this.reset < 2 && !this.closed ? data.encasement_date : null,
                payment_mode: data.payment_mode,
                amount: amountToShow,
                physician: data.physician,
                payee: data.payee,
                payee_type: data.payee_type,
                payer: data.payer,
                payer_type: data.payer_type,
                details: [],
                comment: data.comment
            });

            this.getData();

            // this.payerChange(true);
            // this.payeeChange(true);
        }

        deleteEncasement(ev) {
            this.paymentService.deleteEncasement(this.model, ev)
                .then(() => this.paymentService.mainState(), () => this.paymentService.mainState())
        }

        dissociate() {
            this.paymentService.encasementState(this.model, 1)
                .then(this.state.reload);
        }

        headInvalidate() {
            this.paymentService.encasementState(this.model, 2)
                .then(this.state.reload);
        }

        clear() {
            this.total = 0;
            this.payablePromise = null;
            this.payables = [];
            this.model.payer = null;
            this.model.payee = null;
        }

        patientChanged() {
            if (this.model.payee) {
                this.model.payee = _.assign({}, this.paymentService.getCls("patient"), this.model.payee);
            } else {
                this.model.payee = {};
            }

            this.payeeChange();
            this.getData();
        }

        organizationCallback() {
            this.payeeChange();
            this.getData();
        }

        _onReorder(order) {
            this.query.order = order;
        }

        _onPaginate(page, limit) {
            this.query = _.assign({}, this.query, {
                page: page,
                limit: limit
            });
        }

        getData() {
            if (!this.isDental && !this.hasMedicalCare) this.getSimpleData();
            else this.getFullData();
        }

        getSimpleData() {
            this.payablePromise = this.q.all([this.getVisits()])
                .then(data => this.getDataSuccess(data), _.noop);
        }

        getFullData() {
            const promises = [this.getVisits()];

            if (this.isDental) promises.push(this.getDentalTreatmentPlan());
            if (this.hasMedicalCare) promises.push(this.getMedicalPlan());

            this.payablePromise = this.q.all(promises)
                .then(data => this.getDataSuccess(data), _.noop);
        }

        getVisits() {
            if (this.model.payee_type === "P")
                return this.paymentService
                    .getVisits({patient: this.model.payee.id, hide_paid: true, only_patient: true, all_items: true});

            if (this.model.payee_type === "T")
                return this.paymentService
                    .getOrganizationVisits({
                        organization: this.model.payee.id,
                        only_organization: true,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });
        }

        getDentalTreatmentPlan() {
            if (this.model.payee_type === "P")
                return this.paymentService
                    .getDentalTreatmentPlan({
                        patient: this.model.payee.id,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });

            if (this.model.payee_type === "T")
                return this.paymentService
                    .getOrganizationTreatmentPlan({
                        organization: this.model.payee.id,
                        only_organization: true,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });
        }

        getMedicalPlan() {
            if (this.model.payee_type === "P")
                return this.paymentService
                    .getMedicalPlan({
                        patient: this.model.payee.id,
                        hide_paid: true,
                        only_patient: true,
                        all_items: true
                    });

            //if (this.model.payee_type === "T")
            // return this.paymentService
            //     .getOrganizationTreatmentPlan({
            //         organization: this.model.payee.id,
            //         only_organization: true,
            //         hide_paid: true,
            //         only_patient: true,
            //         all_items: true
            //     });
        }

        getDataSuccess(data) {
            this.selectedPayable = null;
            this.total = _.sumBy(data, 'length');
            this.payableSummary = _.assign(this.payableSummary, {
                gross_total: _.sumBy(data, 'gross_total'),
                paid_amount: _.sumBy(data, 'paid_amount'),
                remaining_amount: _.sumBy(data, 'remaining_amount')
            });

            this.payables = _.reduce(data, (result, item, index) => {
                return _.concat(result, _.reduce(item.list, (payables, payable) => this._preparePayable(index)(payables, payable), []));
            }, []);

            if (this.total < ((this.query.page - 1) * this.query.limit)) {
                this.query.page = 1;
            }
        }

        _preparePayable(index) {
            let types = [
                {
                    type: "visit",
                    date: "visit_date",
                    procedures: "procedures",
                    uid: 1
                }
            ];

            if (this.isDental) types.push({
                type: "plan",
                date: "creation_date",
                procedures: "teeth_procedures",
                uid: -1
            });
            if (this.hasMedicalCare) types.push({
                type: "medical_plan",
                date: "creation_date",
                procedures: "procedures",
                uid: -1
            });

            return (result, value) => {
                let payable = _.find(this.model.details, {id: value.id, type: value.type});
                let remaining_amount = value.financial_status.remaining_amount;

                if (this.model.payee_type === "P" && value.financial_status.organization_affectation) {
                    remaining_amount = value.financial_status.organization_affectation.patient_remaining_amount;
                } else if (this.model.payee_type === "T") {
                    remaining_amount = value.financial_status.organization_affectation.remaining_amount;
                }

                if (_.isUndefined(payable)) {
                    value.model = {
                        uid: types[index].uid * value.id,
                        global_discount: value.financial_status.global_discount,
                        has_global_discount: value.financial_status.paid_amount > 0,
                        remaining_amount: _.ceil(remaining_amount, 2),
                        total_amount: 0,
                        type: types[index].type,
                        date: _.get(value, types[index].date, '-'),
                        hasProcedures: _.get(value, types[index].procedures, []).length > 0
                    }
                } else {
                    value.model = {
                        is_selected: true,
                        global_discount: payable.model.global_discount,
                        has_global_discount: payable.financial_status.paid_amount > 0,
                        total_amount: payable.model.total_amount,
                    }
                }

                return _.concat(result, value);
            }
        }

        showProcedures(payable, $event) {
            $event.stopPropagation();

            if (this.selectedPayable === payable.id) {
                this.selectedPayable = -1;
            } else {
                this.selectedPayable = payable.id;
            }
        }

        rowInputClick($event) {
            $event.stopPropagation();
        }

        rowInputChange(payable) {
            this.scope.$applyAsync(() => {
                _.pushOrUpdate(this.model.details, payable);
                this._detailSelection(payable.model.total_amount > 0, false, payable.model.total_amount)(payable);
            });
        }

        getTotal(payable = null) {
            const total = _.chain(this.model.details)
                .filter(item => item.model.uid !== _.get(payable, "model.uid"))
                .reduce((result, value) => result + value.model.total_amount, 0)
                .value();

            return _.isNaN(total) ? 0 : total;
        }

        validateSelectedVisits() {
            return (this.model.details.length > 0 && this.getTotal() > 0) || this.model.details.length === 0;
        }

        validatePayee() {
            return _.has(this.model.payee, "id");
        }

        _detailSelection(selected = true, selectAll = false, amount = null) {
            if (selected) {
                return payable => {
                    if (this.model.amount === 0) {
                        payable.model.total_amount = payable.model.remaining_amount;
                    } else {
                        const currentTotal = this.getTotal(payable);
                        const remainingAmount = this.model.amount - currentTotal;
                        const amountToPay = _.cloneDeep(amount) || payable.model.remaining_amount;
                        if (this.model.amount === currentTotal) {
                            this._detailSelection(false)(payable);
                            _.remove(this.model.details, ['model.uid', payable.model.uid]);
                            if (selectAll) return false;
                        } else if (amountToPay < remainingAmount) {
                            payable.model.total_amount = amountToPay;
                        } else {
                            payable.model.total_amount = remainingAmount;
                        }
                    }
                    return true;
                }
            } else {
                return payable => {
                    payable.model.is_selected = selected;
                    payable.model.total_amount = 0;
                    return true;
                }
            }
        }

        _allDetailSelection(select = true) {
            if (select) {
                return () => {
                    this.scope.$applyAsync(() => {
                        _.forEach(this.getPayables(true), payable => {
                            _.pushOrUpdate(this.model.details, payable);
                            return this._detailSelection(true, true)(payable);
                        });

                        if (this.model.amount === 0) this.model.amount = this.getTotal();
                    });
                }
            } else {
                return () => {
                    _.forEach(this.model.details, this._detailSelection(false));
                    this.model.details = [];

                    if (this.amountChanged) this.model.amount = 0;
                }
            }
        }

        goBack() {
            window.history.back();
        }
    }

    module.exports = MainPaymentFormCtrl;

})();
